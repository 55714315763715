import React from 'react';
import { Modal } from "@mui/material";
import './ContactModal.css';

export function ContactModal({
    isOpen,
    toggleOpen,
}: {
    isOpen: boolean;
    toggleOpen: () => void;
}) {
    const phoneNumber = "(505) 750-0018";

    const formatPhoneNumber = (number: string) => {
        return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    };

    return (
        <Modal 
            className="contact-modal" 
            open={isOpen} 
            onClose={toggleOpen}
            disableScrollLock={true}
        >
            <div className="contact-modal-content">
                <img src="/images/logo.png" alt="logo" />
                <h2>Contact Us</h2>
                <p>Call or text us at:</p>
                <a href={`tel:${phoneNumber.replace(/\D/g, '')}`} className="phone-link">
                    {formatPhoneNumber(phoneNumber)}
                </a>
                <span className="phone-text">{formatPhoneNumber(phoneNumber)}</span>
            </div>
        </Modal>
    )
}

export default ContactModal;
