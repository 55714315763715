import React, { useState, useEffect } from 'react';
import { Modal, Typography, Box } from "@mui/material";
import { Product } from "../BestSellers";
import './ProductModal.css'
import parse from 'html-react-parser';
import FullSizeImageViewer from '../../../FullSizeImageViewer';

export function ProductModal({
    isOpen,
    toggleOpen,
    product,
}: {
    isOpen: boolean;
    toggleOpen: () => void;
    product: Product
}) {
    const [isFullSizeImageOpen, setIsFullSizeImageOpen] = useState(false);
    const [isProductModalVisible, setIsProductModalVisible] = useState(true);

    useEffect(() => {
        if (!isOpen) {
            setIsFullSizeImageOpen(false);
            setIsProductModalVisible(true);
        }
    }, [isOpen]);

    useEffect(() => {
        const header = document.querySelector('.header');
        if (isOpen || isFullSizeImageOpen) {
            header?.classList.add('modal-open');
        } else {
            header?.classList.remove('modal-open');
        }

        return () => {
            header?.classList.remove('modal-open');
        };
    }, [isOpen, isFullSizeImageOpen]);

    const openFullSizeImage = () => {
        setIsFullSizeImageOpen(true);
        setIsProductModalVisible(false);
    };

    const closeFullSizeImage = () => {
        setIsFullSizeImageOpen(false);
        setIsProductModalVisible(true);
    };

    return (
        <>
            <Modal 
                className={`product-modal ${isProductModalVisible ? 'visible' : 'hidden'}`}
                open={isOpen && !isFullSizeImageOpen}
                onClose={toggleOpen}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
                disableScrollLock={true}
            >
                <Box className="product-modal-content">
                    <div className="product-modal-close" onClick={toggleOpen}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                    </div>
                    <div className="product-modal-content-image" onClick={openFullSizeImage}>
                        <img src={product.image} alt={product.name} loading="lazy" />
                    </div>
                    <div className="product-modal-content-info">
                        <Typography variant="h3" component="h3" className="product-modal-name">{product.name}</Typography>
                        <div className="product-modal-tags">
                            {product.type && (
                                <span className={`badge type-badge ${product.type.toLowerCase()}-badge`}>
                                    {product.type}
                                </span>
                            )}
                            {product.price && product.priceUnit && (
                                <span className="badge price-badge">
                                    {product.price} {product.priceUnit}
                                </span>
                            )}
                            {product.percentage && (
                                <span className="badge percentage-badge">
                                    {product.percentage}
                                </span>
                            )}
                        </div>
                        <Typography 
                            variant="body1" 
                            className="product-modal-description" 
                            component="p"
                        >
                            {parse(product.description || "") || ""}
                        </Typography>
                    </div>
                </Box>
            </Modal>
            <FullSizeImageViewer
                imageSrc={product.image}
                onClose={closeFullSizeImage}
                isOpen={isFullSizeImageOpen}
            />
        </>
    )
}
