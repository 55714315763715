export const validatePhoneNumber = (phone: string): boolean => {
  // US phone number format: (XXX) XXX-XXXX or XXXXXXXXXX
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return phoneRegex.test(phone);
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const validateUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const sanitizeInput = (input: string): string => {
  return input
    .replace(/[<>]/g, '') // Remove < and >
    .replace(/javascript:/gi, '') // Remove javascript: protocol
    .replace(/on\w+=/gi, '') // Remove event handlers
    .trim();
};

export const validateMapCoordinates = (lat: number, lng: number): boolean => {
  return (
    lat >= -90 && lat <= 90 && // Valid latitude range
    lng >= -180 && lng <= 180 // Valid longitude range
  );
};

export const sanitizePhoneNumber = (phone: string): string => {
  return phone.replace(/[^0-9]/g, '');
};

// Rate limiting utility
export class RateLimiter {
  private requests: { [key: string]: number[] } = {};
  private maxRequests: number;
  private timeWindow: number;

  constructor(maxRequests: number = 100, timeWindow: number = 60000) {
    this.maxRequests = maxRequests;
    this.timeWindow = timeWindow;
  }

  isAllowed(clientId: string): boolean {
    const now = Date.now();
    if (!this.requests[clientId]) {
      this.requests[clientId] = [now];
      return true;
    }

    // Remove old requests
    this.requests[clientId] = this.requests[clientId].filter(
      time => now - time < this.timeWindow
    );

    if (this.requests[clientId].length < this.maxRequests) {
      this.requests[clientId].push(now);
      return true;
    }

    return false;
  }
} 