import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import './AgeVerification.css';

export function AgeVerification({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
    const [showModal, setShowModal] = useState(false);
    const [age, setAge] = useState<number | false>(false);
    const [formActive, setFormActive] = useState(true);
    const [formState, setFormState] = useState({
        month: '',
        year: '',
        day : ''
    });
    const [showGoBack, setShowGoBack] = useState(false);

    const months = [
        { value: '01', label: '01 - January' },
        { value: '02', label: '02 - February' },
        { value: '03', label: '03 - March' },
        { value: '04', label: '04 - April' },
        { value: '05', label: '05 - May' },
        { value: '06', label: '06 - June' },
        { value: '07', label: '07 - July' },
        { value: '08', label: '08 - August' },
        { value: '09', label: '09 - September' },
        { value: '10', label: '10 - October' },
        { value: '11', label: '11 - November' },
        { value: '12', label: '12 - December' }
    ];

    useEffect(() => {
        checkLocalStorage();
    }, []);

    useEffect(() => {
        if(age && age >= 21 && age < 120) {
            setShowModal(false);
            onClose();
        } else if (age !== false && age < 21) {
            setShowModal(true);
            setShowGoBack(true);
        }
    }, [age, onClose])

    // validate month
    useEffect(() => {
        if(formState.month) {
            const month = Number(formState.month);
            if(month < 0) {
                setFormState({
                    ...formState,
                    month: '12'
                })
            }
            if(month > 12) {
                setFormState({
                    ...formState,
                    month: '1'
                })
            }
        }
    },  [formState.month])

    // validate day
    useEffect(() => {
        if(formState.day) {
            const day = Number(formState.day);
            if(day < 0) {
                setFormState({
                    ...formState,
                    day: '31'
                })
            }
            if(day > 31) {
                setFormState({
                    ...formState,
                    day: '1'
                })
            }
        }
    }, [formState.day])

    // validate year
    useEffect(() => {
        if(formState.year) {
            const year = Number(formState.year);
            if(year === 0 || year > new Date().getFullYear()) {
                setFormState({
                    ...formState,
                    year: new Date().getFullYear().toString()
                })
            }
        }
    }, [formState.year])

    useEffect(() => {
        if (!isOpen) {
            // Reset state when modal is closed
            setFormState({ month: '', year: '', day: '' });
            setShowGoBack(false);
        }
    }, [isOpen]);

    const checkLocalStorage = () => {
        const age = localStorage.getItem('age');
        const expires = localStorage.getItem('ageExpires');
        const currentDate = new Date();
        const ageExpires = new Date(expires || '')
        if (age && Number(age) >= 21 && ageExpires && ageExpires > currentDate) {
            setAge(Number(age));
        } else {
            setShowModal(true);
        }
    }

    const saveInLocalStorage = (age : number) => {
        const currentDate = new Date();
        // expires in 2 days
        const ageExpires = new Date(currentDate.getTime() + 1000 * 60 * 60 * 24 * 2);
        localStorage.setItem('age', age.toString());
        localStorage.setItem('ageExpires', ageExpires.toString());
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!formState.month || !formState.year || !formState.day) {
            return;
        }
        if(Number(formState.month) < 1 || Number(formState.month) > 12) {
            return;
        }
        if(Number(formState.day) < 1 || Number(formState.day) > 31) {
            return;
        }
        if(Number(formState.year) < 1900 || Number(formState.year) > new Date().getFullYear()) {
            return;
        }

        // calculate age in years from date input year-month-day
        const { month, year, day } = formState;
        const birthDate = new Date(Number(year), Number(month) - 1, Number(day));
        const currentDate = new Date();
        const ageInMilliseconds = currentDate.getTime() - birthDate.getTime();
        const ageInYears = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25));

        setAge(ageInYears);
        if (ageInYears >= 21) {
            saveInLocalStorage(ageInYears);
            setShowGoBack(false);
            onClose();
        } else {
            setShowGoBack(true);
        }
    };

    const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numValue = parseInt(value);
        if (value === '' || (numValue >= 1 && numValue <= 12)) {
            setFormState({ ...formState, month: value });
            
            // Only move to next field if two digits are entered or if 10, 11, or 12 is entered
            if (value.length === 2 || (numValue >= 10 && numValue <= 12)) {
                document.getElementById('dayInput')?.focus();
            }
        }
    };

    const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numValue = parseInt(value);
        if (value === '' || (numValue >= 1 && numValue <= 31)) {
            setFormState({ ...formState, day: value });
            if (value.length === 2) {
                document.getElementById('yearInput')?.focus();
            }
        }
    };

    const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Allow any numeric input
        if (/^\d*$/.test(value)) {
            setFormState({ ...formState, year: value });
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, nextField: string) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            document.getElementById(nextField)?.focus();
        }
    };

    const handleGoBack = () => {
        window.location.href = "https://www.google.com";
    };

    const handleClose = () => {
        if (age && age >= 21) {
            setShowModal(false);
            onClose();
        } else {
            window.location.href = "https://www.google.com";
        }
    };

    return (
        <Modal
            className="age-verification-modal"
            open={showModal || isOpen}
            onClose={handleClose}
            disableScrollLock={true}
        >
            <div className="age-verification-modal-content" style={{ backgroundColor: '#000', color: '#fff', position: 'relative', padding: '20px', borderRadius: '10px' }}>
                <img src="/images/logo.png" alt="logo" style={{ width: '100px', height: '100px', margin: '0 auto', display: 'block' }} />
                <h2 style={{ textAlign: 'center', marginTop: '20px' }}>Welcome!</h2>
                <p style={{ textAlign: 'center', marginBottom: '20px' }}>
                    {age !== false && age < 21 ? (
                        <>
                            <span className="caution-sign">⚠️</span>
                            You must be 21 years old or older to access this site.
                            <span className="caution-sign">⚠️</span>
                        </>
                    ) : (
                        'Please enter your date of birth to continue.'
                    )}
                </p>

                <form onSubmit={handleSubmit} className="age-verification-modal-form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className="age-verification-modal-form-date" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '20px' }}>
                        <input
                            list="months"
                            placeholder="MM"
                            disabled={!formActive}
                            type="text"
                            value={formState.month}
                            onChange={handleMonthChange}
                            onKeyPress={(e) => handleKeyPress(e, 'dayInput')}
                            style={{ backgroundColor: '#333', color: '#fff', border: '1px solid #555', borderRadius: '5px', padding: '10px', width: '30%' }}
                        />
                        <datalist id="months">
                            {months.map(month => (
                                <option key={month.value} value={month.value}>{month.label}</option>
                            ))}
                        </datalist>
                        <input
                            id="dayInput"
                            placeholder="DD"
                            disabled={!formActive}
                            type="number"
                            value={formState.day}
                            onChange={handleDayChange}
                            onKeyPress={(e) => handleKeyPress(e, 'yearInput')}
                            min="1"
                            max="31"
                            style={{ backgroundColor: '#333', color: '#fff', border: '1px solid #555', borderRadius: '5px', padding: '10px', width: '30%' }}
                        />
                        <input
                            id="yearInput"
                            placeholder="YYYY"
                            disabled={!formActive}
                            type="text"
                            inputMode="numeric"
                            value={formState.year}
                            onChange={handleYearChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSubmit(e as any);
                                }
                            }}
                            style={{ backgroundColor: '#333', color: '#fff', border: '1px solid #555', borderRadius: '5px', padding: '10px', width: '30%' }}
                        />
                    </div>
                    <button 
                        type="submit" 
                        style={{ 
                            backgroundColor: '#146C43', 
                            color: '#fff', 
                            border: 'none', 
                            borderRadius: '5px', 
                            padding: '10px 20px', 
                            cursor: 'pointer', 
                            width: '100%',
                            transition: 'background-color 0.3s ease'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0f5132'}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#146C43'}
                    >
                        Submit
                    </button>
                </form>
                
                {showGoBack && (
                    <button 
                        onClick={() => window.location.href = "https://www.google.com"}
                        style={{ 
                            backgroundColor: 'transparent',
                            border: '2px solid #146C43',
                            color: '#146C43',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            marginTop: '20px',
                            width: '100%',
                            transition: 'all 0.3s ease'
                        }}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#146C43';
                            e.currentTarget.style.color = '#fff';
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = 'transparent';
                            e.currentTarget.style.color = '#146C43';
                        }}
                    >
                        Go Back
                    </button>
                )}
            </div>
        </Modal>
    )
}
