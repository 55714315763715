import React, { useEffect } from 'react';
import './ContactBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faClock, faPhone } from '@fortawesome/free-solid-svg-icons';
import { validatePhoneNumber } from '../../../utils/validation';
import { monitoring } from '../../../services/monitoring';

interface ContactBarProps {
  config: {
    address: string;
    phone: string;
    openHours: string;
  };
}

const ContactBar: React.FC<ContactBarProps> = ({ config }) => {
  const formatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  useEffect(() => {
    if (!config.phone || !validatePhoneNumber(config.phone)) {
      console.error('Invalid phone number in config');
      monitoring.logError(new Error('Invalid phone number'), { phone: config.phone });
    }
    if (!config.address) {
      console.error('Missing address in config');
      monitoring.logError(new Error('Missing address'));
    }
  }, [config]);

  return (
    <div className="contact-bar">
      <div className="container">
        <div className="contact-bar-content">
          <div className="contact-bar-item">
            <FontAwesomeIcon icon={faMapPin} />
            <a 
              href="https://maps.app.goo.gl/AaYyXGZhe2fr8Dto8"
              target="_blank"
              rel="noopener noreferrer"
              className="address-link"
            >
              {config.address}
            </a>
          </div>
          <div className="contact-bar-item">
            <FontAwesomeIcon icon={faClock} />
            <span>{config.openHours}</span>
          </div>
          <div className="contact-bar-item">
            <FontAwesomeIcon icon={faPhone} />
            <a href={`tel:${config.phone}`} className="phone-link">
              {formatPhoneNumber(config.phone)}
            </a>
            <span className="phone-text">{formatPhoneNumber(config.phone)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBar;
