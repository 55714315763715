import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { monitoring } from './services/monitoring';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// After ReactDOM.render
const registerServiceWorker = async (): Promise<void> => {
  try {
    await serviceWorker.register();
    console.log('Service worker registered successfully');
  } catch (error: unknown) {
    console.error('Service worker registration failed:', error);
    if (error instanceof Error) {
      monitoring.logError(error, { 
        context: 'Service Worker Registration',
        timestamp: new Date().toISOString()
      });
    }
  }
};

registerServiceWorker();
