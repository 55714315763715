interface LogEvent {
  type: 'error' | 'security' | 'performance' | 'audit';
  message: string;
  data?: any;
  timestamp?: string;
}

export class Monitoring {
  private eventQueue: any[] = [];
  private readonly endpoint: string;
  private readonly maxRetries = 3;
  private readonly retryDelay = 1000; // 1 second base delay

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  private async flush() {
    if (this.eventQueue.length === 0) return;

    const events = [...this.eventQueue];
    this.eventQueue = [];

    for (let retryCount = 0; retryCount < this.maxRetries; retryCount++) {
      try {
        const response = await fetch(this.endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(events)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        return; // Success, exit the retry loop
        
      } catch (error) {
        console.error(`Attempt ${retryCount + 1} failed:`, error);
        
        if (retryCount === this.maxRetries - 1) {
          // Last attempt failed
          console.error('Failed to send logs after all retries');
          this.eventQueue = [...events, ...this.eventQueue];
          return;
        }

        // Wait before next retry, with exponential backoff
        await new Promise(resolve => 
          setTimeout(resolve, this.retryDelay * Math.pow(2, retryCount))
        );
      }
    }
  }

  public logError(error: Error, context: { [key: string]: any } = {}) {
    const event = {
      type: 'error',
      timestamp: new Date().toISOString(),
      error: {
        message: error.message,
        stack: error.stack,
        ...context
      }
    };

    this.eventQueue.push(event);
    this.flush().catch(console.error);
  }

  public logSecurityEvent(message: string, data?: any) {
    const event = {
      type: 'security',
      timestamp: new Date().toISOString(),
      message,
      data
    };

    this.eventQueue.push(event);
    this.flush().catch(console.error);
  }

  public logCSPViolation(violation: SecurityPolicyViolationEvent) {
    const event = {
      type: 'security',
      timestamp: new Date().toISOString(),
      message: 'CSP Violation',
      data: {
        blockedURI: violation.blockedURI,
        violatedDirective: violation.violatedDirective,
        originalPolicy: violation.originalPolicy
      }
    };

    this.eventQueue.push(event);
    this.flush().catch(console.error);
  }

  public destroy() {
    this.eventQueue = [];
  }
}

export const monitoring = new Monitoring(process.env.REACT_APP_MONITORING_ENDPOINT || '/api/logs');