import { Product } from "../BestSellers";
import './ProductCard.css';
import { Typography } from "@mui/material";
import parse from 'html-react-parser';
export default function ProductCard({product, openProductModal}: {
    product: Product,
    openProductModal?: (product: Product) => void,
}) {

    return (
        <div className="product-card" style={{
            display: product.hidden ? 'none' : 'block',
        }}
            onClick={() => openProductModal && openProductModal(product)}
        >
            <div className="product-image"
                style={{
                    backgroundImage: `url(${product.image})`
                }}
            >
                {
                    product.price && product.priceUnit && <span className="badge price-badge">{product.price} {product.priceUnit}</span>
                }
                {
                    product.hotBadge && <span className="badge hot-badge">HOT!</span>
                }
                <div className="product-img-bottom-badges">
                {
                    product.percentage && <span className="badge percentage-badge">{product.percentage}</span>
                }
                {
                    product.type && <span className={`badge type-badge ${product.type.toLocaleLowerCase()}-badge`}>{product.type}</span>
                }
                </div>
                
            </div>
            <div className="product-details">
                <Typography variant="caption" component="p" textAlign="left" width="100%" fontWeight={700}>{product.type}</Typography>
                <Typography variant="h3" component="h3" className="product-name">{product.name}</Typography>
                {/* description */}
                <Typography variant="body1" className="product-description" component="p" textAlign="left" width="100%" fontWeight={700}>{parse(product.description || "") || ""}</Typography>
            </div>
            <div className="product-metadata" style={{
                display: 'none'
            }}>
                <div className="product-metadata-item">{product.name}</div>
                <div className="product-metadata-item">{product.unit}</div>
                <div className="product-metadata-item">{product.price} {product.priceUnit}</div>
                <div className="product-metadata-item">{product.percentage}</div>
                <div className="product-metadata-item">{product.type}</div>
            </div>
        </div>
    )
}
