import parse from 'html-react-parser';
import './ExperienceMore.css';

export default function ExperienceMore({ config }: {
    config: {
        title: string;
        bottomText: string;
        items: {
            title: string;
            text: string;
        }[]
    }
}) {
    return (
        <section className="experience-more">
            <div className="container">
                <h2 className="experience-more-title">{parse(config.title)}</h2>
                <div className="experience-more-items">
                    {
                        config.items.map((item, index) => (
                            <div className="experience-more-item" key={index}>
                                <h3 className="experience-more-item-title">{item.title}</h3>
                                <p className="experience-more-item-text">{item.text}</p>
                            </div>
                        ))
                    }
                </div>
                <p className="experience-more-bottom-text">{config.bottomText}</p>
            </div>
        </section>
    )
}