import { faCircleChevronLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import './ImageSlider.css';

const ImageSlider = ({ images }: {
    images: string[]
}) => {
    const [currentImage, setCurrentImage] = useState(0)
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const [transition, setTransition] = useState("");

    const [touchStartX, setTouchStartX] = useState(0);
    const [ didInteract, setDidInteract ] = useState(false);
    useEffect(() => {
        if (didInteract) {
            // clear interact after 5 seconds
            const timeout = setTimeout(() => {
                setDidInteract(false);
            }, 5000);
            return () => clearTimeout(timeout);
        }
        const interval = setInterval(() => {
            setCurrentImage((prev) => (prev + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [images.length, didInteract]);

    const nextImage = () => {
        setCurrentImage((currentImage + 1) % images.length)
        setDidInteract(true);
        transit();
    }
    const prevImage = () => {
        setCurrentImage((currentImage - 1 + images.length) % images.length)
        setDidInteract(true);
        transit();
    }

    const transit = () => {
        setTransition("transition-" + 'fade');
        setTimeout(() => {
            setTransition("");
        }, 100);
    };

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setTouchStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        const touchEndX = e.changedTouches[0].clientX;
        const diff = touchStartX - touchEndX;
        if (diff > 50) {
            nextImage();
        } else if (diff < -50) {
            prevImage();
        }
    };

    return (
        <div className="image-slider" ref={ref =>  sliderRef.current = ref} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
            <div className="prev-button">
                <button onClick={prevImage}><FontAwesomeIcon icon={faCircleChevronLeft}/></button>
            </div>
            <div className="current-image">
                {/* <TransitionGroup>
                    <Slide timeout={200} key={images[currentImage]} in={true} direction="left">
                        <img src={images[currentImage]} alt="slider" />
                    </Slide>
                </TransitionGroup> */}
               {
                images.map((image, index) => (
                    <LazyLoadImage
                        className={`slider-image ${transition}`}
                        key={index}
                        src={image}
                        alt="slider"
                        style={{
                            display: index === currentImage ? 'block' : 'none'
                        }}
                    />
                ))
               }
                
            </div>

            <div className="next-button">
            <button onClick={nextImage}><FontAwesomeIcon icon={faCircleChevronRight}/></button>
            </div>
        </div>
    )
}


export default ImageSlider;