import React, { useEffect, useState, useMemo } from "react";
import ProductCard from "./Products/ProductCard";
import './BestSellers.css';
import { Collapse } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import { ProductModal } from "./Products/ProductModal";

export interface Product {
    name: string;
    type: string;
    description?: string;
    percentage: string;
    unit: string;
    price: string;
    priceUnit: string;
    image: string;
    hotBadge: boolean;
    hidden: boolean;
    id?: string;
}

const MemoizedProductCard = React.memo(ProductCard);

interface BestSellersProps {
    config: {
        title: string;
        backgroundImage: string;
        items: Product[];
    };
    setProductModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function BestSellers({ config, setProductModalOpen }: BestSellersProps) {
    const seperatedItems = config.items.reduce((acc, item, index) => {
        if(item.hidden) {
            acc.hide.push(item);
        } else {
            acc.show.push(item);
        }
        return acc;
    }, {
        show: [] as Product[],
        hide: [] as Product[],
    } as {
        show: Product[];
        hide: Product[];
    });
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(8);
    const [products, setProducts] = useState<Product[]>([]);
    const [hasMore, setHasMore] = useState(true);

    const [modalProduct , setModalProduct] = useState<Product | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        setProducts(seperatedItems.show.slice(startIndex, endIndex));
    }, [])

    const tryNextPage = () => {
        const maybeStartIndex = startIndex + 9;
        let maybeEndIndex = endIndex + 9;
        if(maybeEndIndex >= seperatedItems.show.length) {
            setHasMore(false);
            maybeEndIndex = seperatedItems.show.length;
        }
        if(maybeStartIndex >= seperatedItems.show.length) {
            return;
        }
        setStartIndex(maybeStartIndex);
        setEndIndex(maybeEndIndex);
        const newProducts = [...products, ...seperatedItems.show.slice(maybeStartIndex, maybeEndIndex)]
        setProducts(newProducts);
    }

    const handleViewLess = () => {
        setStartIndex(0);
        setEndIndex(8);
        setProducts(seperatedItems.show.slice(0, 8));
        setHasMore(true);
    }

    const setModalProductAndOpen = (product: Product) => {
        setModalProduct(product);
        setIsModalOpen(true);
        setProductModalOpen(true);
    }

    const visibleProducts = useMemo(() => {
        return seperatedItems.show.slice(0, endIndex);
    }, [seperatedItems.show, endIndex]);

    return (
        <section className="best-sellers" >
            <a className="anchor" href="hidden" id="best-sellers"></a>
            <div className="container">
                <h2 className="best-sellers-title">{config.title}</h2>
                    <div className="best-sellers-products">
                    {
                        <TransitionGroup className="best-sellers-products">
                            {
                                visibleProducts.map((product, index) => (
                                    <Collapse timeout={500} key={index}><MemoizedProductCard key={index} product={product} openProductModal={setModalProductAndOpen} /></Collapse>
                                ))
                            }
                        </TransitionGroup>
                    }
                    {
                        seperatedItems.hide.map((product, index) => (
                            <MemoizedProductCard key={`hidden-${product.id || index}`} product={product} />
                        ))
                    }
                    </div>
                    {
                        hasMore ? <button className="best-sellers-load-more" onClick={tryNextPage}>View More</button> : <button className="best-sellers-load-more" onClick={handleViewLess}>View Less</button>
                    }
            </div>
            <div className="best-sellers-overlay">
                <div  style={{
                    backgroundImage: `url(${config.backgroundImage})`,
                    // tile background image
                    backgroundSize: '200px 200px',
                    width: '100%',
                    height: '100%',

                }}></div>
            </div>
            {modalProduct && (
                <ProductModal 
                    isOpen={isModalOpen} 
                    toggleOpen={() => {
                        setIsModalOpen(false);
                        setProductModalOpen(false);
                    }} 
                    product={modalProduct} 
                />
            )}
        </section>
    )
}
