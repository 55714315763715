import { useEffect, useRef, useState } from 'react';
import './Navbar.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import useTransition, { TransitionStatus } from 'react-transition-state';
import { styled } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';

const Box = styled('div')`
    transition: all 500ms;
    ${({ $status }: { $status: TransitionStatus}) =>
        ($status === "preEnter" || $status === "exiting") &&
        `
        opacity: 0;
        `}
`

export default function Navbar() {
    const location = useLocation()
    const navigation = useNavigate();
    const [shrink, setShrink] = useState(false);
    const scrollY = useRef<number>(0);

    const handleScroll = () => {
        const oldScrollY = scrollY.current;
        scrollY.current = window.scrollY;
        // check if scrolling up, if so do not shrink
        if(oldScrollY > scrollY.current) {
            setShrink(false);
            return;
        }

        setShrink(window.scrollY > 100);
    }


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    const [{ status, isMounted }, toggle] = useTransition({
        timeout: 500,
        mountOnEnter: true,
        unmountOnExit: true,
        preEnter: true,
      });

      const handleClick = () => {
        if(window.innerWidth < 768) {
            toggle();
        }
    }

    const handleLogoClick = () => {
        navigation('/');
    }
    const menu = (mobile: boolean = false) => (
        <>
            <li onClick={handleClick} className={`nav-item${mobile?'-mobile':''} ${location.pathname === '/' && location.hash.includes('hero-section') ? 'active' : ''}`}>
                <Link className="nav-link" to="/#hero-section">Home</Link>
            </li>
            <li onClick={handleClick} className={`nav-item${mobile?'-mobile':''} ${location.pathname === '/' && location.hash.includes('best-sellers') ? 'active' : ''}`} >
                <Link className="nav-link" to="/#best-sellers">Shop</Link>
            </li>
            <li onClick={handleClick} className={`nav-item${mobile?'-mobile':''} ${location.pathname === '/about' ? 'active' : ''}`} >
                <Link className="nav-link" to="/about">About</Link>
            </li>
        </>
    )


  return (
    <header className={"header" + (shrink ? ' shrink' : '')}>
        {/* Navbar */}
        <nav className={"navbar navbar-expand-lg navbar-light bg-light " + (shrink ? 'shrink' : '')}>
            <ul className="navbar-nav">
                {menu()}
            </ul>
            <div className="navbar-mobile-menu">
                <div className="navbar-mobile" onClick={() => toggle()}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
                {
                    isMounted && 
                    <Box $status={status}>
                        <ul className="navbar-nav-mobile" >
                            {menu()}
                        </ul>
                    </Box>
                }
                
            </div>

        </nav>
        {/* Logo */}
        <div className={"logo" + (shrink ? ' shrink' : '')} onClick={handleLogoClick}>
            <img src="images/logo.png" alt="Logo" />
        </div>
        {/* Search Bar - Not implemented yet */}
        <div className="search-bar">
        </div>
    </header>
  );
}