import { Box } from "@mui/material";

export default function Loading() {
    return <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: '#000',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
        }}
    >
        <img src="images/loading.gif" alt="loading" style={{ width: '100px', height: '100px' }} />
    </Box>;
}