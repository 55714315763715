import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import './Reviews.css';
import ReviewComponent from "./ReviewComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function Reviews({config}: 
    {
        config: {
            title: string;
            reviews: {
                name: string;
                review: string;
                stars: number;
            }[]
        }
    }
) {

    const [review, setReview] = useState(0);
    const [touchStartX, setTouchStartX] = useState(0);
    const [ didInteract, setDidInteract ] = useState(false);
    useEffect(() => {
        if (didInteract) {
            // clear interact after 5 seconds
            const timeout = setTimeout(() => {
                setDidInteract(false);
            }, 5000);
            return () => clearTimeout(timeout);
        }
        const interval = setInterval(() => {
            setReview((prev) => (prev + 1) % config.reviews.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [config.reviews.length , didInteract]);

    const handleNext = () => {
        setDidInteract(true);
        setReview((prev) => (prev + 1) % config.reviews.length);
    }

    const handlePrevious = () => {
        setDidInteract(true);
        setReview((prev) => (prev - 1 + config.reviews.length) % config.reviews.length);
    }



    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setTouchStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        const touchEndX = e.changedTouches[0].clientX;
        const diff = touchStartX - touchEndX;
        if (diff > 50) {
            handleNext();
        } else if (diff < -50) {
            handlePrevious();
        }
    };

  return (
    <section className="reviews-section"
    >
        <div className="reviews-title-container">
                <h2 className="reviews-title">{parse(config.title)}</h2>
        </div>
        <div className="container">
            <div className="reviews-pagination-container" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                <div className="reviews-pagination-button previous" onClick={handlePrevious}><FontAwesomeIcon icon={faArrowLeft} /></div>
                <div className="review-container">
                    <ReviewComponent review={config.reviews[review]} />
                </div>
                <div className="reviews-pagination-button next" onClick={handleNext}><FontAwesomeIcon icon={faArrowRight}/></div>
            </div>
        </div>
    </section>
  )
}