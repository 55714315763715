import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';

export function Footer () {
    const [mapLoaded, setMapLoaded] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        console.log("Footer mounted, initializing map observer");
        setIsLoading(false);

        const observer = new IntersectionObserver(
            ([entry]) => {
                console.log("Map intersection state:", {
                    isIntersecting: entry.isIntersecting,
                    intersectionRatio: entry.intersectionRatio
                });
                
                if (entry.isIntersecting) {
                    console.log("Map is in view, setting mapLoaded to true");
                    setMapLoaded(true);
                    observer.disconnect();
                }
            },
            { 
                rootMargin: "100px",
                threshold: 0.1 // Trigger when at least 10% is visible
            }
        );

        // Delay the observer to ensure the element is in the DOM
        setTimeout(() => {
            const footerMap = document.querySelector(".footer-map");
            if (footerMap) {
                observer.observe(footerMap);
                console.log("Map element found and being observed");
            } else {
                console.warn("Footer map element not found in DOM");
            }
        }, 100);

        return () => {
            console.log("Footer unmounting, disconnecting observer");
            observer.disconnect();
        };
    }, []);

    React.useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();

        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const handleMapError = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
        console.error('Map failed to load:', {
            src: e.currentTarget.src,
            error: e
        });
        e.currentTarget.style.display = 'none';
        const mapContainer = document.querySelector('.footer-map');
        if (mapContainer) {
            mapContainer.innerHTML = '<div class="map-error">Map unavailable - Please try again later</div>';
        }
    };

    console.log("Footer render state:", {
        mapLoaded,
        isMobile,
        isLoading
    });

    if (isLoading) {
        return <div className="footer-loading">Loading...</div>;
    }

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="logo">
                    <img 
                        src="images/logo.png" 
                        alt="Budding Bar Dispensary Logo" 
                        onError={(e) => {
                            e.currentTarget.style.display = 'none';
                            console.error('Failed to load logo');
                        }}
                    />
                </div>
                <div className="links-and-map-container">
                    <div className="footer-links">
                        <h2>Keep in touch</h2>
                        <ul>
                            <li>
                                {isMobile ? (
                                    <Button
                                        variant="contained"
                                        href="tel:+15057500018"
                                        startIcon={<FontAwesomeIcon icon={faPhone} />}
                                        className="footer-button phone-button"
                                        aria-label="Call us at (505) 750-0018"
                                    >
                                        (505) 750-0018
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<FontAwesomeIcon icon={faPhone} />}
                                        className="footer-button phone-button"
                                        aria-label="Phone number: (505) 750-0018"
                                        sx={{ cursor: 'default' }}
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        (505) 750-0018
                                    </Button>
                                )}
                            </li>
                            <li>
                                <Button
                                    variant="contained"
                                    href="mailto:buddingbar@gmail.com"
                                    startIcon={<FontAwesomeIcon icon={faEnvelope} />}
                                    className="footer-button"
                                    aria-label="Email us at buddingbar@gmail.com"
                                >
                                    buddingbar@gmail.com
                                </Button>
                            </li>
                            <li>
                                <Button
                                    variant="contained"
                                    href="https://www.facebook.com/people/Budding-Bar-Dispensary/100092347293333/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    startIcon={<FontAwesomeIcon icon={faFacebookF} />}
                                    className="footer-button facebook-button"
                                    aria-label="Visit our Facebook page"
                                >
                                    Facebook
                                </Button>
                            </li>
                            <li>
                                <Button
                                    variant="contained"
                                    href="https://maps.app.goo.gl/AaYyXGZhe2fr8Dto8"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    startIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                                    className="footer-button"
                                    aria-label="Get directions to 804 S Main St. Belen, NM 87002"
                                >
                                    804 S Main St. Belen, NM 87002
                                </Button>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-map">
                        {!mapLoaded ? (
                            <div className="map-loading">
                                <div>Loading map...</div>
                                <div className="loading-indicator"></div>
                            </div>
                        ) : (
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.5338675947547!2d-106.78140237697091!3d34.65281716743612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8721f104f79e4749%3A0x5230789cf85fb6f8!2sBudding%20Bar%20%26%20Dispensary!5e0!3m2!1sen!2sus!4v1710799614744!5m2!1sen!2sus"
                                width="300"
                                height="200"
                                style={{ border: 0 }}
                                allowFullScreen={false}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Google Maps location of Budding Bar Dispensary"
                                onError={handleMapError}
                                onLoad={() => console.log("Map iframe loaded successfully")}
                            />
                        )}
                    </div>
                </div>
            </div>
        </footer>
    )
}
