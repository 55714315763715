import Rating from '@mui/material/Rating';

const ReviewComponent = ({review}: {review: {
    name: string;
    review: string;
    stars: number;
}}) => {
    return (
        <div className="review">
            <div className="review-header">

                <h3>{review.name}</h3>
                {/* Includes half stars */}
                <Rating name="half-rating-read" style={{
                    color: "#fff"
                }} value={review.stars} precision={0.5} readOnly />
            </div>
            <p>{review.review}</p>

        </div>
    )
}

export default ReviewComponent;