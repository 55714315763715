import React, { useEffect } from 'react';
import { Modal, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';

const ModalContent = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 95vw;
  max-height: 75vh;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  @media screen and (max-width: 950px) {
    max-width: 95vw;
    max-height: 75vh;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  top: 35px;
  right: 35px;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
  z-index: 10001;
  min-width: unset;
  line-height: 0;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  &:hover {
    color: #fff;
    transform: scale(1.1);
  }

  svg {
    width: 21px;
    height: 21px;
    transition: transform 0.3s ease;
    display: block;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.8));
  }

  &:hover svg {
    transform: rotate(90deg);
  }
`;

const Image = styled.img`
  max-width: 95vw;
  max-height: 75vh;
  object-fit: contain;
  width: auto;
  height: auto;
  background-color: #000;
  padding: 20px;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;

interface FullSizeImageViewerProps {
  imageSrc: string;
  onClose: () => void;
  isOpen: boolean;
}

const FullSizeImageViewer: React.FC<FullSizeImageViewerProps> = ({ imageSrc, onClose, isOpen }) => {
  useEffect(() => {
    const header = document.querySelector('.header');
    if (isOpen) {
      header?.classList.add('modal-open');
    } else {
      header?.classList.remove('modal-open');
    }

    // Cleanup function to ensure the header is shown when component unmounts
    return () => {
      header?.classList.remove('modal-open');
    };
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="full-size-image-modal"
      aria-describedby="fullscreen-image-viewer"
      disableScrollLock={true}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',  // Adjust backdrop opacity
        }
      }}
    >
      <ModalContent>
        <CloseButton onClick={onClose} aria-label="Close full-size image">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
        </CloseButton>
        <Image src={imageSrc} alt="Full size product" />
      </ModalContent>
    </Modal>
  );
};

export default FullSizeImageViewer;
