import React from "react";
import parse from 'html-react-parser';
import './HeroSection.css';
import ImageSlider from "./ImageSlider";

export default function HeroSection({config}: 
    {
        config: {
            title: string;
            buttonText: string;
            images: string[];
        }
    }
) {

  return (
    <section className="hero-section-about"
    >
            {/* <img src={config.backgroundImage} alt="Background Image" /> */}
        <div className="container">
            { config.images.length > 0 && <ImageSlider images={config.images} /> }
            <h1 className="hero-title-about">{parse(config.title)}</h1>
            {/* <a href="/" className="link-button">{config.buttonText}</a> */}
        
        </div>
    </section>
  )
}