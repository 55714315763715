import React from 'react';
import ExperienceMore from './ExperienceMore/ExperienceMore';
import HeroSection from './HeroSection/HeroSection';
import './About.css';
import AboutSection from './AboutSection/AboutSection';

function AboutPage({config}: {
    config: any;
}) {
  const experienceMoreConfig = {
    title: "Experience <span>More</span>, <br/> For <span>Less</span>",
    bottomText: "Visit Budding Bar & Dispensary today and discover a community that values quality, luxury, and your satisfaction above all else.",
    items: [
      {
        title: "🌟 Welcome to Budding Bar & Dispensary 🌟",
        text: "Discover premium cannabis with exclusive perks curated just for you. We're here to offer not just products—but an experience."
      },
      {
        title: "✨ First-Time Guests Enjoy Exclusive Savings",
        text: "Explore our selection and receive a personalized discount on your first purchase. A luxurious introduction to our world of quality."
      },
      {
        title: "💎 Premium Price-Match Promise",
        text: "We guarantee the best prices in Belen. Found a better deal elsewhere? We'll match it—hassle-free. Your satisfaction is non-negotiable."
      },
      {
        title: "🎖️ Celebrating Veterans & Seniors",
        text: "Enjoy exclusive savings tailored for veterans and seniors. Stack these benefits with our offers for maximum value—because you deserve it."
      },
      {
        title: "🎁 Luxe Rewards Program",
        text: "With every purchase, you earn points toward curated discounts and limited offers. Let your shopping unlock premium experiences over time."
      },
      {
        title: "🤝 Refer & Earn Together",
        text: "Love our products? Refer a friend and both of you instantly earn 100 bonus points. There's no limit—spread the word, rack up rewards."
      },
      {
        title: "🌐 Share Your Voice & Get Rewarded",
        text: "Help us grow by leaving a Google review and earn 100 reward points as a token of our gratitude. Your feedback drives our journey forward."
      },
      {
        title: "🌱 Join Us in Growth",
        text: "As a proud member of our community, your support makes a difference. Together, we grow—your loyalty, our commitment."
      },
      {
        title: "💼 Elevate Your Cannabis Experience",
        text: "Visit us today at Budding Bar & Dispensary and discover a community that values quality, luxury, and your satisfaction above all else."
      }
    ]
  };

  return (
    <div className="AboutPage">
      {config['hero-section'] && <HeroSection config={config['hero-section']} />}
      {config['about-section'] && <AboutSection config={config['about-section']} />}
      <ExperienceMore config={experienceMoreConfig} />
    </div>
  );
}

export default AboutPage;
