import React from 'react'
import './AboutSection.css';

const AboutSection = ({ config }: {
    config: {
        title: string;
        text: string;
        buttonText: string;
        buttonLink: string;
        image: string;
    }
}) => {
  return (
    <section className="about-section">
        <div className="container">
            <div className="about-section-container">
                <div className="about-section-image">
                    <img src={config.image} alt="About Image" />
                </div>
                <div className="about-section-content">
                    <h2>{config.title}</h2>
                    <p>{config.text}</p>
                    <a href={config.buttonLink} className="link-button">{config.buttonText}</a>
                </div>
            </div>
        </div>
    </section>
  )
}   

export default AboutSection;